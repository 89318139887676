import {
  faAngleDown,
  faBars,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";

import application from "../../assets/PDF/forms/Application ID Card.pdf";
import clearance from "../../assets/PDF/forms/গ্রন্থাগার ছাড়পত্র.docx";
import teacherForm from "../../assets/PDF/forms/শিক্ষকদের জন্য.jpg";

import juLogo from "../../assets/images/logo-ju main.jpg";
import "./Header.style.scss";
const Header = () => {
const [menuOpen, setMenuOpen] = useState(false);
const [mobileView, setMobileView] = useState(false);
const [activeDropdowns, setActiveDropdowns] = useState([]);

useEffect(() => {
  const handleResize = () => {
    setMobileView(window.innerWidth < 1000);
  };
  window.addEventListener("resize", handleResize);
  handleResize(); 

  return () => {
    window.removeEventListener("resize", handleResize);
  };
}, []);

const toggleMenu = () => {
  setMenuOpen(!menuOpen);
};

const handleDropdownToggle = (index) => {
  setActiveDropdowns((prev) => {
    const newState = [...prev];
    newState[index] = !newState[index];
    return newState;
  });
};

return (
  <nav>
    <div className="container">
      <div className="row">
        <div className="logo">
          <img src={juLogo} alt="Logo" />
        </div>
        <div className="mobile_btn" onClick={toggleMenu}>
          <FontAwesomeIcon
            icon={menuOpen ? faXmark : faBars}
            className="fa-xl"
          />
        </div>
        <div
          className={`main_menu ${menuOpen && mobileView ? "active" : ""}`}
        >
          <ul>
            <li>
              <a href="/" className="active">
                Home
              </a>
            </li>
            <li>
              <a href="https://juniv.edu">JU Home</a>
            </li>
            <li>
              <a href="/">About</a>
            </li>
            <li className="has_dropdown">
              <a href="#" onClick={() => handleDropdownToggle(0)}>
                Services <FontAwesomeIcon icon={faAngleDown} />
              </a>
              <ul
                className={`sub_menu ${activeDropdowns[0] ? "active" : ""}`}
              >
                <li>
                  <a
                    href="https://opac.juniv.edu"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Library Catalogue
                  </a>
                </li>
                <li>
                  <a
                    href="https://repository.juniv.edu"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Institutional Repository
                  </a>
                </li>
                <li>
                  <a
                    href="https://journals.juniv.edu"
                    target="_blank"
                    rel="noreferrer"
                  >
                    JU Journals
                  </a>
                </li>
                <li>
                  <a
                    href="https://pis.juniv.edu"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Digital ID Card
                  </a>
                </li>
                <li>
                  <a
                    href="https://my.openathens.net/?passiveLogin=false"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Remote Access
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.ithenticate.com"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Plagiarism Detection Software
                  </a>
                </li>
                <li>
                  <a
                    href="https://docs.google.com/spreadsheets/d/1ZZ7ja5YwK34JljQwQE_6K0y5KEG1cYY6/edit?pli=1&gid=729923670#gid=729923670"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Thesis Lists
                  </a>
                </li>
              </ul>
            </li>

            <li className="has_dropdown">
              <a href="#" onClick={() => handleDropdownToggle(0)}>
                Resources <FontAwesomeIcon icon={faAngleDown} />
              </a>
              <ul
                className={`sub_menu ${activeDropdowns[0] ? "active" : ""}`}
              >
                <li>
                  <a href="/" target="_blank" rel="noreferrer">
                    Resources 1
                  </a>
                </li>
                <li>
                  <a href="/" target="_blank" rel="noreferrer">
                    Resources 2
                  </a>
                </li>
                <li>
                  <a href="/" target="_blank" rel="noreferrer">
                    Resources 3
                  </a>
                </li>
              </ul>
            </li>

            <li>
              <a href="https://juniv.edu/office/university-library/program/8253">
                A-Z DB
              </a>
            </li>
            <li>
              <a href="/">Digital Library</a>
            </li>

            <li className="has_dropdown">
              <a href="#" onClick={() => handleDropdownToggle(0)}>
                Downloads <FontAwesomeIcon icon={faAngleDown} />
              </a>
              <ul
                className={`sub_menu ${activeDropdowns[0] ? "active" : ""}`}
              >
                <li>
                  <a href={application} target="_blank" rel="noreferrer">
                    Application ID Card
                  </a>
                </li>
                <li>
                  <a href={clearance} target="_blank" rel="noreferrer">
                    Library Clearance
                  </a>
                </li>
                <li>
                  <a href={teacherForm} target="_blank" rel="noreferrer">
                    University Id Card{" "}
                  </a>
                </li>
              </ul>
            </li>
            <li>
              <a href="https://opac.juniv.edu">Log In</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </nav>
);
};

export default Header;
